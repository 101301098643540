:pioneer-default {
  --primary-color: #1442cc;
  --primary-color-darker: rgb(20, 66, 204, 0.8);
  --primary-color-header: #1e0109;
  --secondary-color: #a90533;
  --background-color: #1e0109;
  --text-color: #ffffff;
}

.heading {
  background: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid #dedede;
  margin-bottom: 32px;
}
.icons {
  display: flex;
  color: var(--primary-color);
}
.deviceHeading {
  font-weight: bold;
  margin: 0 0 16px 0;
}
.paragraph {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #4822b4;
}

.spinner-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999; /* Ensure it's on top of other content */
}

.spinner-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* semi-transparent black */
  z-index: 9998; /* Ensure it's below the spinner */
}

.devicescreen {
  /* padding-left: 35px; */
}

.resetButton {
  background-color: transparent;
  border: 1px solid black;
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
  max-width: fit-content;
}

.resetButton:hover {
  background-color: #f0f0f0;
  /* Change to desired hover color */
}

.resetButton:focus {
  outline: none;
}

#searchInput {
  flex: "1";
  max-width: "600px";
  border: "1px solid #080808";
  border-radius: "5px 0 0 5px";
  padding: "5px 35px 5px 35px";
}

.spanInput {
  position: "absolute";
  left: "10px";
  top: "50%";
  transform: "translateY(-50%)";
  cursor: "pointer";
}

.buttongroup {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

/* Custom CSS to override form-control styles for dropdown */
.form-control {
  appearance: auto; /* Ensure native styling for select */
}

.form-control::-ms-expand {
  display: block; /* Ensure the dropdown arrow is displayed */
}

.pagenate {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  font: "Rubik";
}

.paginationBttns {
  width: 100%;
  height: 24px;
  list-style: none;
  display: flex;
  justify-content: center;
  color: var(--primary-color);
}

.paginationBttns a {
  padding: 10px;
  margin: 8px;
  border-style: hidden;
  border-radius: 5px;
  text-decoration: none;
  color: var(--primary-color);
  cursor: pointer;
}
.previous a,
.next a {
  height: 24px;
  width: 24px;
  border: 1px solid #3498db; /* Border color */
}
.paginationBttns :hover {
  color: var(--text-color);
}

.paginationBttns a:hover {
  color: var(--text-color);
  background-color: var(--primary-color-darker);
}

.paginationActive a {
  /* color: var(--text-color); */
  background-color: var(--primary-color);
}

.paginationActive {
  color: #f8f2f2;
}

.paginationDisabled a {
  color: grey;
}
.actionButton {
  background-color: transparent;
  border: 1px solid;
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
  max-width: fit-content;
  color: var(--primary-color);
  margin: 0 0 0 16px;
}

.actionButton:hover {
  background-color: #f0f0f0;
  /* Change to desired hover color */
}

.actionButton:focus {
  outline: none;
}
.table-head > tr > th {
  background-color: #f5f5f5;
  color: #120f10;
  font-weight: bold;
}

.tableRowShade {
  background-color: white;
}

.tableRowShade:nth-child(even) {
  background-color: #f5f5f5;
}
.filters {
  margin: 1%;
  box-shadow: 0 0 2px 1px rgba(205, 206, 206, 0.66);
  padding: 10px;
}

.emptyData {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  /* padding: 10px; */
  font-weight: bold;
  margin: 16px;
}
.updatedata {
  padding: 16px;
  margin: 1%;
}
.csvdownloadlink {
  border: 1px solid red;
}
