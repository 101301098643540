.file-upload-container {
  display: flex;
  flex-direction: column;
  padding: 10px;
  gap: 10px;
  align-items: center;
}

.file-upload {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 400px; /* Adjust the width as needed */
  height: 300px; /* Adjust the height as needed */
  padding: 12px;
  border: 2px dotted #019d91; /* Increase the border width */
  border-radius: 12px;
  /* color: #019d91; */
  background-color: rgb(231, 247, 242);
  cursor: pointer;
  transition: border-color 0.3s ease;
}

.file-upload:hover {
  border-color: #0a5780;
}

.upload-text {
  flex-grow: 1;
  padding-top: 7px;
  cursor: pointer;
  color: #019d91;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
}

.upload-text :hover {
  color: #0a5780;
}

#upload-input {
  display: none;
}

.upload-button {
  margin-top: 12px;
  padding: 10px 20px;
  border: none;
  border-radius: 6px;
  background-color: #019d91;
  color: white;
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;
}

.upload-button:hover {
  background-color: #0a5780;
}

.upload-button:disabled {
  background-color: #d1d1d1;
  color: #a5a5a5;
  cursor: not-allowed;
  opacity: 0.6;
}

.upload-button:disabled:hover {
  background-color: #d1d1d1;
}
