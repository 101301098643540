:pioneer-default {
  --primary-color: #1442cc;
  --primary-color-darker: rgb(20, 66, 204, 0.8);
  --primary-color-header: #1e0109;
  --secondary-color: #a90533;
  --background-color: #1e0109;
  --text-color: #ffffff;
}

.navigation {
  font-weight: bold;
  cursor: pointer;
  /* background-color: antiquewhite; */
  display: flex;
  flex-direction: row;
  vertical-align: middle;
  align-items: center;
  color: var(--primary-color);
  padding-top: 1%;
}

.navigation:hover {
  color: #00a0f0;
}

.backarrow {
  padding-top: 1%;
  padding-bottom: 1%;
  font-size: 28px;
}
