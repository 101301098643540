:pioneer-default {
  --primary-color: #1442cc;
  --primary-color-darker: rgb(20, 66, 204, 0.8);
  --primary-color-header: #1e0109;
  --secondary-color: #a90533;
  --background-color: #1e0109;
  --text-color: #ffffff;
}

.createvehicle {
  align-items: center;
  /* border: 2px solid #161717a8; */
  padding: 32px;
}

.createVehicleHeading {
  font-weight: bold;
}

.links {
  font-weight: 600;
  font: "Rubik";
  padding-left: 20%;
  text-decoration-line: #4822b4;
}

.col-sm-8 :disabled {
  background-color: #e6fafd;
}

.dropdown-menu {
  background-color: #f8f9fa; /* replace with your desired color */
}

.name {
  font-weight: bold;
  cursor: pointer;
}

.separator {
  color: black;
}

#mandatorylabels::after {
  content: " *";
  color: red;
}

.filters {
  /* margin-top: 1%; */
  box-shadow: 0 0 2px 1px rgba(205, 206, 206, 0.66);
  padding: 10px;
}

.nodata {
  /* border: 1px solid rgb(59, 12, 4); */
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  padding: 10px;
  font-weight: bold;
}

.rowalign {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.columnalign {
  display: flex;
  align-items: center;
  gap: 5px;
  justify-content: flex-start;
}

.boldText {
  font-weight: bold;
}
