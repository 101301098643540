* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@import url("https://fonts.googleapis.com/css2?family=Rubik&display=swap");

.MuiTableCell-root {
  font-family: "Rubik", sans-serif;
}

/* Default Theme */
/* :root {
--primary-color: #5C8AFF;
--primary-color-header: #000000;
--secondary-color: #4822B4;
--background-color: #19172C;
--text-color: white;
} */

[data-theme="pioneer-default"] {
  --primary-color: #1442cc;
  --primary-color-darker: rgb(20, 66, 204, 0.8);
  --primary-color-header: #1e0109;
  --secondary-color: #a90533;
  --background-color: #1e0109;
  --text-color: #ffffff;
}

/* Red and Black */
[data-theme="red-black"] {
  --primary-color: #913838;
  --primary-color-header: #000000;
  --secondary-color: #000000;
  --background-color: #913838;
  --text-color: white;
}

/* Blue and Green */
[data-theme="blue-green"] {
  --primary-color: #b1d5aa;
  --primary-color-header: #05153c;
  --secondary-color: #05153c;
  --background-color: #b1d5aa;
  --text-color: #000000;
}

/* Ocean Green and Yellow */
[data-theme="ocean-green-yellow"] {
  --primary-color: #f3f381;
  --primary-color-header: #008080;
  --secondary-color: #008080;
  --background-color: #f6f69f;
  --text-color: #000000;
}

.main-container {
  /* flex: 1; */
  margin-top: 25%;
  display: flex;
}
main {
  padding-left: 20%;
  padding-top: 3%;
}

.title {
  font-size: 3rem;
  display: grid;
  place-items: center;
}

/* Sidebar */
.sidebar {
  width: 15%;
  overflow-wrap: break-word;
  /* max-width: 50%;
  margin: 0 auto;
  flex: 0 0 18%; */
  /* margin-top: 5%; */
  background: var(--background-color);
  color: var(--text-color);
  height: 100%;
  position: fixed;
  /* overflow-y: auto; */
  transition: width 0.5s;
}

.sidebar.closed {
  margin-top: 2%;
  width: 4%;
  visibility: visible; /* Hide the sidebar when closed */
}

.sidebar::-webkit-scrollbar {
  width: 0.2em;
  background-color: #f5f5f5;
}

.sidebar::-webkit-scrollbar-thumb {
  background-color: #000000;
}

.sidebar {
  -ms-overflow-style: none; /* Internet Explorer and Edge */
  overflow: -moz-scrollbars-none; /* Firefox */
}

.top_section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 10px;
  /* color:   var(--secondary-color); */
}

h1 {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font: 300;
  color: var(--secondary-color);
}

.ham-menu {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 10px;
}
.logo {
  font-size: 18px;
  line-height: 0;
}
.bars {
  cursor: pointer;
  /* width: 35px; */
}
.hide {
  display: none;
}

.icon {
  color: var(--text-color);
}

.icon .active {
  color: white;
}

.routes {
  /* font-size: 90%; */
  margin-top: 20%;
  display: flex;
  flex-direction: column;
  gap: 15px;
  /* border-bottom: 1px solid  */
}
.routes a {
  /* margin-bottom: 20%; */
  border-bottom: 1px solid #302e2f;
}
.link {
  display: flex;
  align-items: center;
  color: var(--text-color);
  font: "Rubik";
  gap: 10px;
  padding: 5px 10px;
  text-decoration: none;
  border-right: 4px solid transparent;
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
}
.link:hover {
  /* border-right: 4px solid white; */
  background: var(--secondary-color);
  color: white;
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
}
.link.active {
  color: white;
}
.active {
  margin: 0 0 0 8px;
  border-radius: 10px 0 0 10px;
  background: var(--secondary-color);
}
.link_text {
  white-space: nowrap;
  font-size: 15px;
  font: "Rubik";
  margin-top: 2%;
  overflow: hidden;
}

.menu {
  display: flex;
  color: var(--text-color);
  cursor: pointer;
  padding: 5px 10px;
  /* border-right: 4px solid transparent; */
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
  justify-content: space-between;
}

.menu_item {
  display: flex;
  gap: 10px;
}
.menu_container {
  display: flex;
  flex-direction: column;
}
.menu_container .link {
  padding-left: 20%;
}

.header {
  background: var(--background-color);
  cursor: pointer;
}

.header:hover {
  color: var(--primary-color);
}

.usernameMenu {
  margin-top: 1%;
  margin-left: 3%;
}

.btn {
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
}

.btn:hover {
  background-color: var(--primary-color-darker) !important;
  border-color: var(--primary-color) !important;
  color: #000000 !important;
}
/* Footer Section */
.sidebar-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 15px;
  text-align: center;
  font-size: 14px;
  color: var(--text-color);
  background-color: var(
    --primary-color-header
  ); /* You can change this color if you prefer */
  /* border-top: 1px solid var(--secondary-color); Optional: add a border for separation */
}

.sidebar-footer p {
  margin: 0;
  font-size: 12px; /* Optional: adjust font size */
}

/* Optional: Make the footer text smaller if sidebar is closed */
.sidebar.closed .sidebar-footer {
  font-size: 12px;
  padding: 10px;
}
