.status {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.statustwo {
  color: blueviolet;
}

.statussecondLine {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.tableheading {
  font-weight: bold;
}

.progress {
  background-color: #d8d8d8;
  border-radius: 20px;
  position: relative;
  /* margin: 15px 0; */
  height: 25px;
  width: 150px;
}

.progress-bar {
  box-shadow:
    0 3px 3px -5px #70b8f2,
    0 2px 5px #70a4f2;
  border-radius: 20px;
  color: #000;
  font-weight: bold;
  font-size: medium;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 0;
  opacity: 0;
  transition: 1s ease 0.3s;
}
.done {
  background: #2ea611;
}
.inProgress {
  background: #ffd274;
}
.fail {
  background: #cc1414;
}
